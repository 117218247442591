import React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import styles from "./404.module.css";
import Layout from "../components/layout";

const NotFoundPage = ({
  data
}) => (
  <>
    <Layout coverImage={data.coverImage.childImageSharp.fluid}>
      <Helmet>
        <title>(404) Verloren - Silbar</title>
      </Helmet>
      <article className={styles.lost}>
        <h1 className={styles.title}>Verloren</h1>
        <p className={styles.text}>Zo voelen we ons nu allebei.</p>
        <p className={styles.text}>Maar... ook dit zal voorbij gaan.</p>
        <p className={styles.text}>Klik maar snel hierboven om je weg te vervolgen.</p>
      </article>
    </Layout>
  </>
);

export default NotFoundPage;

export const pageQuery = graphql`
  {
    coverImage: file(relativePath: { eq: "404.jpg" }) {
      childImageSharp {
        fluid(
          quality: 50,
          maxWidth: 1600) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    },
  }
`;
